import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import HomecareTreatments from "./HomecareTreatments";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import CustomMap from "ps-components/Maps/CustomMap";
// const searchRegExp = /<networkHospitalCount \/>/g
// const stringSearchRegExp = /<stringNetworkHospitalCount \/>/g
// const HOSPITALCOUNT = process.env.HOSPITALCOUNT;
// const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

var apiBaseUrl = process.env.API_URL;

const PHCSwalkin = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
    
//     if(content){
//     if (document.node.strapiId == 283) {
//       obj.tabButton = `Diagnostic Centres`;
//     } else {
//       if(document.node.strapiId==700 || document.node.strapiId==701 ){
//         obj.tabButton=document.node.title.replace(/Network Providers for/g, '').replace(/&/g,"/").replace(/Treatment/g,'CARE')+' PROVIDER'
//       }else{
//       obj.tabButton = document.node.title;
//       }
//     }
//     obj.tabImage = document.node.icon.publicURL;
//     obj.tabContent = content;
//     navPillArr.push(obj);
//   }

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem style={{ padding: `70px 0px` }}>
              <GridItem
        md={10}
        sm={10}
        className={classNames(classes.mrAuto, classes.mlAuto)}>
        <h2 className={classNames(classes.title, classes.textCenter)}>
        Preventive Health Check Services – Walk In
        </h2>
      </GridItem>
              <GridItem md={12}>
        <CustomMap
          id="diagnostics"
          searchMessage="Search Diagnostic Centers"
          category="diagnostics"
          icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
          icon_size={25}
          url={`${apiBaseUrl}hospital/search`}
          cityUrl={`${apiBaseUrl}hospital/city`}
          defaultView={`list`}
          helperText={`diagnostic centers`}
          disableListView={process.env.DISABLE_LISTVIEW === `true` ? true : false}
        />
      </GridItem>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
PHCSwalkin.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object
};
export default withStyles(customStaticPageStyle)(PHCSwalkin);

export const PHCSwalkinQuery = graphql`
  query PHCSwalkin {
    allStrapiSections(
      filter: { category: { eq: "network_hospital" } }
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          title
          summary
          strapiId
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "locate_us" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
